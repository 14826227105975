<template>
  <div class="table_list_orders_block">
    <div class="sort_block">
      <div>
        <div class="block_date_picker">
          <span class="title">Фильтр по дате:</span>
          <el-date-picker
            v-model="dateSort"
            type="daterange"
            format="dd.MM.yyyy"
            range-separator="-"
            start-placeholder="Начало"
            end-placeholder="Конец"
            :picker-options="this.pickerOptions"
          >
          </el-date-picker>
        </div>
        <span class="note"
          >* По умолчанию установлен фильтр по дате: Последняя неделя</span
        >
      </div>
      <div>
        <el-select
          v-model="filterStatusPay"
          clearable
          @clear="clearFilterStatusPay"
          placeholder="Статус оплаты"
        >
          <el-option
            v-for="item in statusPayOrders"
            :key="item.id"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </div>
      <!--      <div>-->
      <!--        <el-select-->
      <!--          v-model="filterStatus"-->
      <!--          clearable-->
      <!--          placeholder="Фильтр по статусу"-->
      <!--        >-->
      <!--          <el-option-->
      <!--            v-for="item in statusOrders"-->
      <!--            :key="item.id"-->
      <!--            :label="item.name"-->
      <!--            :value="item.type"-->
      <!--          >-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
      <div>
        <el-radio-group v-model="statusOrder">
          <el-radio-button label="">Все</el-radio-button>
          <el-radio-button label="cart">Создан</el-radio-button>
          <el-radio-button label="work">В работе</el-radio-button>
          <el-radio-button label="ready">Готов</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div
      class="preloader_block"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="Загрузка списка заказов..."
      element-loading-background="rgb(251, 251, 252)"
    >
      <div class="table_block" v-show="listOrders.length > 0">
        <el-table
          :data="listOrders"
          stripe
          style="width: 100%"
          @current-change="handleCurrentChange"
          :height="'calc(-280px + 100vh)'"
        >
          <el-table-column prop="" label="" width="30">
            <template slot-scope="scope">
              <el-tooltip
                v-if="scope.row.oShow"
                class="item"
                effect="dark"
                content="Заказ создан в системе PrintOutlet."
                placement="top-start"
                :open-delay="300"
              >
                <i class="icon-logo"></i>
              </el-tooltip>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                content="Заказ создан менеджером. Управление недоступно."
                placement="top-start"
                :open-delay="300"
              >
                <i class="icon-agent"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="oNumber" label="Номер заказа" width="180">
            <template slot-scope="scope">
              <i class="el-icon-№"></i>
              <span style="margin-left: 10px">{{ scope.row.oNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oDate" label="Дата" width="180">
            <template slot-scope="scope">
              <i class="el-icon-date"></i>
              <span style="margin-left: 10px">{{ scope.row.oDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oName" label="Продукция">
            <template slot-scope="scope">
              <i class="el-icon-tickets"></i>
              <span style="margin-left: 10px">{{ scope.row.oName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oSumm" label="Сумма" width="180">
            <template slot-scope="scope">
              <i class="el-icon-money"></i>
              <span style="margin-left: 10px"
                >{{ Number(scope.row.oSumm) | numeral("0,0.00") }} ₽</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="IsPay" label="Статус оплаты" width="180">
            <template slot-scope="scope">
              <i :class="iconClassStatusPay(scope.row.IsPay)"></i>
              <span style="margin-left: 10px">{{
                scope.row.IsPay | isPayFilter
              }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label="Документы" width="180">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button-->
          <!--                size="mini"-->
          <!--                type="info"-->
          <!--                icon="el-icon-download"-->
          <!--                plain-->
          <!--                :disabled="scope.row.oBill === null"-->
          <!--                :title="-->
          <!--                  scope.row.oBill === null ? 'Счет не доступен' : 'Скачать счет'-->
          <!--                "-->
          <!--                @click="downloadInvoice(scope.$index, scope.row)"-->
          <!--                >Скачать-->
          <!--              </el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="oStatus" label="Статус" width="180">
            <template slot-scope="scope">
              <i :class="iconClassStatus(scope.row.status)"></i>
              <span style="margin-left: 10px">{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column type="expand" label="Инфо" width="60" height="100%">
            <template slot-scope="props">
              <div class="expand_block">
                <p>
                  <span style="font-weight: bold; margin-right: 8px"
                    >Менеджер:
                  </span>
                  {{ props.row.oManager }}
                </p>
                <p>
                  <span style="font-weight: bold; margin-right: 8px"
                    >Контактное лицо:
                  </span>
                  {{ props.row.oContact | splitContact }}
                </p>
                <p v-if="props.row.oCustomerCompany">
                  <span style="font-weight: bold; margin-right: 8px"
                    >Юр.лицо:
                  </span>
                  {{ props.row.oCustomerCompany | abbreviationFilter }}
                </p>
                <p v-if="props.row.oBill">
                  <span
                    style="margin-right: 8px; font-weight: bold"
                    v-if="props.row.oBill.info !== ''"
                  >
                    {{ props.row.oBill.info }}:
                  </span>
                  <span
                    style="margin-right: 8px"
                    v-if="props.row.oBill.pdf === true"
                  >
                    <el-button
                      size="mini"
                      type="info"
                      icon="el-icon-download"
                      plain
                      title="Скачать счет"
                      @click="downloadInvoice(props.$index, props.row)"
                      >Скачать
                    </el-button>
                  </span>
                  <span v-if="props.row.oBill.pdf === false">
                    <el-button
                      size="mini"
                      type="info"
                      icon="el-icon-document"
                      plain
                      :loading="generateLoadings"
                      title="Сформировать счет к заказу и скачать"
                      @click="
                        generateAndDownloadInvoice(props.$index, props.row)
                      "
                      >Скачать
                    </el-button>
                  </span>
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-show="listOrders.length === 0 && loading === false"
        class="table_block_empty"
      >
        По текущему фильтру заказов нет!
      </div>
    </div>
    <div class="pagination_block" v-show="listOrders.length > 0">
      <el-pagination
        background
        :hide-on-single-page="false"
        :page-count="listOrdersPageCount"
        :current-page="listOrdersCurrentPage"
        @current-change="current_change"
        @size-change="handleSizeChange"
        :page-sizes="[20, 50, 100]"
        :page-size="20"
        layout="total, sizes, prev, pager, next"
        :total="listOrdersTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment";
export default {
  name: "TableListOrders",
  data() {
    return {
      statusOrder: "",
      dateSort: [],
      filterStatus: "",
      statusOrders: [
        { id: 1, name: "Создан", type: "cart" },
        { id: 2, name: "В работе", type: "work" },
        { id: 3, name: "Готов", type: "ready" },
      ],
      filterStatusPay: null,
      statusPayOrders: [
        { id: 1, name: "Оплачен", type: true },
        { id: 2, name: "Не оплачен", type: false },
      ],
      multipleSelection: [],
      pageSize: 20,
      currentPage: 1,
      pageCount: 1,
      statusCurrentChange: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "Последняя неделя",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
          {
            text: "Последний месяц",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
          {
            text: "Последние 3 месяца",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [
                start.setHours(0, 0, 0, 0),
                end.setHours(0, 0, 0, 0),
              ]);
            },
          },
        ],
      },
      loading: false,
      generateLoadings: false,
    };
  },
  computed: {
    formatDate: function () {
      if (this.dateSort !== null) {
        return this.dateSort.map((item, i) => {
          if (i === 1) {
            return moment(item).add("days", 1).format("YYYY-MM-DDTHH:mm:ss");
          } else {
            return moment(item).format("YYYY-MM-DDTHH:mm:ss");
          }
        });
      } else return null;
    },
    listOrders() {
      return this.$store.getters["Orders/listOrders"];
    },
    listOrdersPageCount() {
      return this.$store.getters["Orders/listOrdersPageCount"];
    },
    listOrdersCurrentPage() {
      return this.$store.getters["Orders/listOrdersCurrentPage"];
    },
    listOrdersTotal() {
      return this.$store.getters["Orders/listOrdersTotal"];
    },
  },
  filters: {
    splitContact: function (value) {
      if (!value) return "";
      return value.split(",", 1)[0];
    },
    isPayFilter: function (IsPay) {
      if (IsPay === true) return "Оплачен";
      if (IsPay === false) return "Не оплачен";
    },
    abbreviationFilter: function (string) {
      let re = /Общество с ограниченной ответственностью/gi;
      return string.replace(re, "ООО");
    },
  },
  methods: {
    clearFilterStatusPay() {
      this.filterStatusPay = null;
    },
    downloadInvoice(index, row) {
      this.CreateDownloadLink(row.oBill.link);
    },
    generateAndDownloadInvoice(index, row) {
      this.generateLoadings = true;
      this.$store
        .dispatch("Orders/customerBillGeneratePDF", row.oBill.oid)
        .then((response) => {
          if (
            response.data.status &&
            response.data.status === 2000 &&
            response.data.response.link
          ) {
            this.CreateDownloadLink(response.data.response.link);
          }
        })
        .finally(() => {
          this.generateLoadings = false;
        });
    },
    handleCurrentChange(val) {
      if (val) {
        const oId = val.oid;
        if (val.oShow === true) {
          this.$router.push({
            name: "order",
            params: { oId },
          });
        } else {
          this.$notify({
            title: "Заказ создан менеджером",
            message: "Доп.информация и управление заказом не доступны!",
            type: "info",
            offset: 85,
            duration: 6000,
          });
        }
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getListOrders();
    },
    iconClassStatus(oStatus) {
      if (oStatus === "Готов") return "el-icon-circle-check colorGreen";
      if (oStatus === "В работе") return "el-icon-loading colorRed";
      if (oStatus === "Создан") return "el-icon-aim colorGrey";
      else return "";
    },
    iconClassStatusPay(IsPay) {
      if (IsPay === true) return "el-icon-circle-check colorGreen";
      if (IsPay === false) return "el-icon-time colorRed";
      else return "";
    },
    current_change(currentPage) {
      this.currentPage = currentPage;
      this.$store.commit("Orders/listOrdersCurrentPage", currentPage);
      this.getListOrders();
    },
    defaultDate() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      this.dateSort = [start.setHours(0, 0, 0, 0), end.setHours(0, 0, 0, 0)];
    },
    getListOrders() {
      this.loading = true;
      let request = {
        // type: this.filterStatus, // todo если фильтрация через select
        type: this.statusOrder,
        page: this.currentPage,
        ordersperpage: this.pageSize,
        fromdate: this.formatDate[0],
        todate: this.formatDate[1],
        pay: this.filterStatusPay,
        company: null,
      };
      this.$store.dispatch("Orders/listOrders", request).finally(() => {});
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".preloader_block",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(251, 251, 252)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
    tableRowClassName({ row }) {
      if (row.IsPay === false) {
        return "warning-row";
      } else if (row.IsPay === true) {
        return "success-row";
      }
      return "";
    },
    CreateDownloadLink(linkPdf) {
      let link = document.createElement("a");
      link.setAttribute("href", linkPdf);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "");
      link.click();
    },
  },
  watch: {
    dateSort(value) {
      if (value) {
        this.getListOrders();
      } else if (value === null) {
        this.defaultDate();
        this.getListOrders();
      }
    },
    listOrders() {
      this.loading = false;
    },
    filterStatus() {
      this.getListOrders();
    },
    filterStatusPay() {
      this.getListOrders();
    },
    statusOrder() {
      this.getListOrders();
    },
  },
  mounted() {
    this.defaultDate();

    // this.getListOrders();
  },
  destroyed() {
    this.$store.commit("Orders/listOrders", []); //todo подумать как очищать список и надо ли
  },
};
</script>
<style scoped>
.table_list_orders_block,
.preloader_block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.preloader_block {
  position: relative;
  min-height: calc(-280px + 100vh);
}
.el-table th,
.el-table tr {
  cursor: pointer;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: green;
}
.colorGrey {
  color: gray;
}

.block_date_picker {
  display: flex;
  width: max-content;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}
.note {
  font-size: 12px;
}
.block_date_picker:active {
  border-color: #d2d4da;
}
.block_date_picker .title {
  width: 124px;
  padding: 8px;
  font-size: 14px;
  color: rgb(96, 98, 102);
}
.sort_block {
  display: flex;
  width: max-content;
  height: 60px;
  justify-content: flex-start;
  gap: 24px;
}
.block_date_picker .el-input__inner {
  border: unset !important;
  height: 38px;
  width: 300px;
}
.pagination_block {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.table_block_empty {
  text-align: center;
  font-size: 24px;
}

.el-range-editor .el-range-input {
  color: #ea4f00;
}
.el-select .el-input .el-input__inner {
  color: #ea4f00;
  font-weight: 700;
}
.el-input__inner::placeholder {
  font-weight: 400 !important;
}
.el-pagination .el-select .el-input {
  width: 140px;
  margin: 0 5px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.expand_block {
  display: flex;
  gap: 24px;
  align-items: center;
}

/*.el-loading-mask {*/
/*  height: calc(-280px + 100vh) !important;*/
/*}*/
</style>
